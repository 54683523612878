<div class="hero header-overlay" [style.background]="overlayBackground">
  <div
    class="background-image"
    [style.background-image]="'url(' + content.headerImage + ')'"
    [style.opacity]="content.headerImageOpacity"
  ></div>
  <svg viewBox="0 0 100 15" style="position: absolute; bottom: 0; left: 0; z-index: 2">
    <path fill="#fff" opacity="0.1" d="M0 30 V15 Q30 3 60 15 V30z"></path>
    <path class="wave-fill" d="M0 30 V12 Q30 17 55 12 T100 11 V30z"></path>
  </svg>
  <div class="hero-content">
    <material-navbar
      [transparent]="true"
      [container]="true"
      class="login-button-white"
      [menuPosition]="settings.get('vebto.navbar.defaultPosition')"
    ></material-navbar>
    <div class="be-container">
      <h1 class="header-title" trans>{{ content.headerTitle }}</h1>
      <br>
      <p class="header-description" trans>You want to create a cheap website independent from your website operator (= hoster)?</p>
      <br>
      <p class="header-description" trans>Or are you an entrepreneur and want to have a company website created?</p>
      <br>
      <p class="header-description" trans>It should be uncomplicated and easy - best realized with a homepage builder?</p>
      <br>
      <p class="header-description" trans>Our website builder makes it possible! Simply select, like with a homepage builder, from a variety of modern website templates and place the required elements via drag & drop.</p>
      <br>
      <p class="header-description" trans>It doesn't matter if you use our website builder to create a general classic company website or something very specific, like a restaurant website. There are no limits to your design freedom....</p>
      <br>
      <!--<p class="header-description" trans>{{ content.headerSubtitle }}</p>!-->
      <div class="action-buttons">
        <a
          class="get-started-button"
          mat-raised-button
          color="accent"
          *ngIf="content?.actions?.cta1"
          routerLink="/register"
          trans
          >{{ content.actions.cta1 }}</a
        >
        <button
          class="learn-more-button"
          mat-button
          (click)="scrollToFeatures()"
          *ngIf="content?.actions?.cta2"
          trans
        >
          {{ content.actions.cta2 }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="content.primaryFeatures.length">
  <div class="be-container">
    <ad-host slot="ads.landing.top"></ad-host>
    <ul class="inline-features unstyled-list">
      <li class="inline-feature" *ngFor="let primaryFeature of content.primaryFeatures">
        <image-or-icon class="inherit-size" [src]="primaryFeature.image" alt="homepage baukasten"></image-or-icon>
        <h3 trans>{{ primaryFeature.title }}</h3>
        <p trans>{{ primaryFeature.subtitle }}</p>
      </li>
    </ul>
  </div>

  <div class="spacer"></div>
</ng-container>

<ul class="big-features unstyled-list">
  <ng-container
    *ngFor="
      let secondaryFeature of content.secondaryFeatures;
      first as first;
      last as last;
      even as even
    "
  >
    <li
      class="big-feature be-container"
      [class.first-secondary-feature]="first"
      [class.reverse]="even"
    >
      <img [src]="secondaryFeature.image + '?v2'" alt="websitebuilder" />
      <section class="info">
        <small trans>{{ secondaryFeature.subtitle }}</small>
        <h2 trans>{{ secondaryFeature.title }}</h2>
        <div class="feature-border"></div>
        <p trans>{{ secondaryFeature.description }}</p>
      </section>
    </li>

    <div class="spacer" *ngIf="!last"></div>
  </ng-container>
</ul>

<div class="spacer"></div>

<div class="bottom-cta" [style.background-image]="'url(' + content.footerImage + ')'">
  <div class="be-container">
    <h2 trans class="footer-title">{{ content.footerTitle }}</h2>
    <p trans class="footer-description">{{ content.footerSubtitle }}</p>
    <a mat-stroked-button routerLink="/register" *ngIf="content.actions?.cta1" trans>{{
      content.actions.cta1
    }}</a>
  </div>
</div>

<app-footer class="container"> </app-footer>
